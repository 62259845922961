
import axios from 'axios';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
@Component
export default class RejectJob extends Vue {
  @Prop({ default: null }) partner!: Object;
  @Prop({ default: null }) jobApiId!: string;
  @Prop({ default: null }) rejectionReasons!: string[];

  reasonCategory: string = '';

  get rejectionReasonsOptions() {
    return this.rejectionReasons;
  }
  async rejectJob() {
    const partnerAccountId: string = this.partner?.['partner_id'];
    const jobToReject: object = {
      job: {
        apiId: this.jobApiId,
      },
      partner: {
        id: partnerAccountId,
      },
      reasonCategory: this.reasonCategory,
    };
    return axios
      .post('/data-api/rejected-job/', {
        ...jobToReject,
      })
      .then(this.triggerRefreshPartners);
  }

  @Emit('job-rejected')
  triggerRefreshPartners() {
    return true;
  }
}
