<template>
  <div>
    <b-form-input
      v-if="readonly"
      :value="selectedValue?.label || ''"
      :readonly="true"
    />
    <multiselect
      v-else
      v-model="selectedValue"
      label="label"
      @input="input($event)"
      :options="options"
      track-by="value"
      :searchable="true"
    />
  </div>
</template>

<script>
import { CondOperator, RequestQueryBuilder } from '@dataui/crud-request';
import { ContactProfileEnum } from '@gid/models';
import BaseInput from '@gid/vue-common/components/inputs/mixins/BaseInput';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';

export default {
  mixins: [BaseInput],
  components: {
    Multiselect,
  },
  data() {
    return {
      owners: [],
      job_owner: null,
    };
  },
  watch: {
    readonly(value) {
      if (!value && this.owners.length == 0) {
        this.initOwners();
      }
    },
  },
  computed: {
    ...mapGetters(['locale']),
    selectedValue: {
      get: function () {
        return this.owners.find((item) => item.value == this.value);
      },
      set: function (newValue) {
        this.commitValue(newValue);
        this.job_owner = newValue;
      },
    },
    options() {
      return this.owners;
    },
  },
  created() {
    this.initOwners();
  },
  methods: {
    input(event) {
      if (event) {
        return this.commitValue(event.value);
      }
      return this.job_owner;
    },
    initOwners() {
      const gidAccountId = 1;
      const queryString = RequestQueryBuilder.create({
        filter: [
          {
            field: 'accountId',
            operator: CondOperator.EQUALS,
            value: gidAccountId,
          },
        ],
        fields: ['id', 'name', 'profiles'],
        sort: [{ field: 'name', order: 'ASC' }],
      }).query();

      axios.get(`/data-api/contact?${queryString}`).then((response) => {
        if (response.data.length) {
          this.owners = response.data
            .filter(({ profiles }) =>
              profiles.some(
                ({ profile }) =>
                  profile === ContactProfileEnum.PROJECT_MANAGER_GENERIC,
              ),
            )
            .map(({ id, name }) => ({
              value: id,
              label: name,
            }));
        }
      });
    },
  },
};
</script>
