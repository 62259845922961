
import HtmlRich from '@gid/vue-common/components/HtmlRich.vue';
import RichTextEditor from '@gid/vue-common/components/RichTextEditor.vue';
import TicketFilesSelector from '@gid/vue-common/components/inputs/TicketFilesSelector.vue';
import {
  LiveAgent,
  JobContact,
} from '@gid/vue-common/components/ticketing/interface/ticketing';
import axios from 'axios';
import { BModal } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  components: {
    RichTextEditor,
    'ticket-files-selector': TicketFilesSelector,
    HtmlRich,
    Multiselect,
  },
  computed: mapGetters(['environment']),
})
export default class LiveAgentTicketNew extends Vue {
  @Prop({ default: false, required: true }) visible!: boolean;
  @Prop({ default: null, required: true }) jobContacts!: JobContact[];
  @Prop({ default: () => [], required: true }) jobNames!: string[];
  @Prop({ default: () => [], required: false }) jobs!: any[];

  @Ref('modal')
  readonly modal!: BModal;

  departments: any = [];
  senders: any = [];
  newTicket: LiveAgent.TicketInterface = {
    subject: '',
    departmentid: '',
    recipient: '',
    message: '',
    carbon_copies: '',
    blind_carbon_copies: '',
    status: 'C',
    mail_message_id: null,
    do_not_send_mail: 'N',
    use_template: 'Y',
    is_html_message: 'Y',
    custom_fields: [],
    tags: [],
    attachments: [],
    filePaths: [],
  };
  selectedAgent: any = null;
  error: string = '';
  showLoader: boolean = false;
  selectedContacts: JobContact[] = [];
  categorizedData = {};
  selectedJobRole = null;
  showDiscardTicketModal = false;

  async created() {
    this.departments = await this.forDepartments();
    this.senders = await this.forSenders();
    this.categorizedData = this.jobContactsCategorizedData;
  }
  //Commputed
  get departmentOptions() {
    if (!this.departments || !this.departments.length) {
      return [];
    }
    return this.departments.map((dfg: LiveAgent.Department) => {
      return { text: dfg.name, value: dfg.department_id };
    });
  }
  get senderOptions() {
    if (!this.senders || !this.senders.length) {
      return [];
    }
    return this.senders.map((sender: any) => {
      return { text: sender.name + ' - ' + sender.email, value: sender };
    });
  }
  get jobContactsCategorizedData() {
    const categorizedData = {};

    this.jobContacts.forEach((item) => {
      const role = item.jobRole || '';

      if (!categorizedData[role]) {
        categorizedData[role] = [];
      }

      categorizedData[role].push(item);
    });

    return categorizedData;
  }
  get jobRolesOptions() {
    return Object.keys(this.categorizedData).map((key) => ({
      text: key.toUpperCase(),
      value: key,
    }));
  }
  get recipientEmail() {
    return this.selectedContacts.length > 0
      ? this.selectedContacts[0].email
      : '';
  }
  get ccEmails() {
    if (this.selectedContacts.length <= 1) {
      return '';
    }

    return this.selectedContacts
      .slice(1)
      .map((item) => item.email)
      .join(', ');
  }
  //Methods
  forDepartments = async () => {
    if (!this.departments || !this.departments.length)
      return this.fetchDepartments();
    return this.departments;
  };
  forSenders = async () => {
    if (!this.senders || !this.senders.length) return this.fetchSenders();
    return this.senders;
  };
  async fetchDepartments(): Promise<LiveAgent.Department[]> {
    return axios
      .get(`/data-api/ticket/live-agent/departments`)
      .then((response) => {
        this.error = '';
        return response?.data;
      })
      .catch((error) => {
        this.error = `Error fetching departments`;
        return [];
      });
  }
  async fetchSenders(): Promise<any> {
    return axios
      .get(`/data-api/ticket/live-agent/agents`)
      .then((response) => {
        this.error = '';
        return response?.data;
      })
      .catch((error) => {
        this.error = `Error fetching senders`;
        return [];
      });
  }
  attachment(data: {
    files: { name?: string; data?: string }[];
    filePaths: string[];
  }) {
    this.newTicket.attachments = data.files.map(({ name, data }) => ({
      name: name || '',
      data: data || '',
    }));
    this.newTicket.filePaths = data.filePaths;
  }
  async onCreateTicket() {
    const leaveOnError = (error: string) => {
      this.error = error;
      return;
    };

    if (this.newTicket.departmentid === '')
      return leaveOnError('Abteilung muss definiert werden!');
    if (this.selectedAgent === null)
      return leaveOnError('Absender muss definiert werden!');
    if (this.recipientEmail === '')
      return leaveOnError('Bitte wählen Sie mindestens einen Kontakt aus!');
    if (
      this.newTicket.carbon_copies !== '' &&
      !this.validateEmails(this.newTicket.carbon_copies)
    )
      return leaveOnError(
        'Bitte geben Sie eine gültige Cc-E-Mail-Adresse ein!',
      );
    if (
      this.newTicket.blind_carbon_copies !== '' &&
      !this.validateEmails(this.newTicket.blind_carbon_copies)
    )
      return leaveOnError(
        'Bitte geben Sie eine gültige Bcc-E-Mail-Adresse ein!',
      );
    if (this.newTicket.subject === '')
      return leaveOnError('Betreff muss definiert werden!');
    if (this.newTicket.message === '')
      return leaveOnError('Nachricht muss definiert werden!');

    this.newTicket.recipient = this.recipientEmail || '';
    this.newTicket.carbon_copies = this.ccEmails.trim();

    try {
      this.showLoader = true;
      this.error = '';
      await axios.post(`/data-api/ticket`, {
        ...this.newTicket,
        agentid: this.selectedAgent.id,
        useridentifier: this.selectedAgent.email,
      });
      this.onSuccess();
    } catch (error) {
      this.error = 'Error occured while creating ticket!';
    } finally {
      this.showLoader = false;
    }
  }
  validateEmails(emailsString: string) {
    const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emails = emailsString.split(',');

    return emails.every((email: string) => regEx.test(email.trim()));
  }
  onSuccess() {
    this.ticket_created();
    this.close();
    this.modal.hide();
    this.clear();
  }
  onModalClose() {
    this.clear();
    this.showDiscardTicketModal = false;
    this.modal.hide();
    this.close();
  }
  clear() {
    this.newTicket.departmentid = '';
    this.newTicket.recipient = '';
    this.newTicket.carbon_copies = '';
    this.newTicket.blind_carbon_copies = '';
    this.newTicket.subject = '';
    this.newTicket.message = '';
    this.newTicket.attachments = [];
    this.selectedContacts = [];
    this.selectedJobRole = null;
    this.error = '';
    this.selectedAgent = null;
  }

  @Emit('ticket-created')
  ticket_created() {
    return true;
  }
  @Emit('close')
  close() {
    return true;
  }
  @Watch('selectedJobRole')
  onSelectedjobRole() {
    this.selectedContacts = [];
  }
  @Watch('jobNames')
  onJobNames() {
    if (this.jobNames.length > 0)
      this.jobNames.forEach((name, index) => {
        this.newTicket.custom_fields[index] = {
          code: '01',
          value: name,
        };
      });
  }
}
