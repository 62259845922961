
import { CondOperator, RequestQueryBuilder } from '@dataui/crud-request';
import axios from 'axios';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import { LiveAgent } from '@gid/vue-common/components/ticketing/interface/ticketing';
import { TicketStatusEnum } from '@gid/models';
import LiveAgentTicket from './LiveAgentTicket.vue';

const FetchLiveAgentTickets = async ({
  job: {
    job: { name: jobName },
  },
}) => {
  const queryString = RequestQueryBuilder.create({
    filter: [
      {
        field: 'jobs.name',
        operator: CondOperator.EQUALS,
        value: jobName,
      },
      {
        field: 'status',
        operator: CondOperator.NOT_EQUALS,
        value: TicketStatusEnum.X,
      },
    ],
    sort: [{ field: 'messages.created_at', order: 'ASC' }],
  }).query();

  const tickets = await axios.get(`/data-api/ticket?${queryString}`);
  if (!tickets.data.length) {
    return [];
  }
  return tickets.data;
};

@Component({
  components: {
    LiveAgentTicket,
  },
  computed: mapGetters(['environment']),
})
export default class TicketsList extends Vue {
  @Prop({ default: null }) jobName!: string;
  @Prop({ default: null }) refreshTickets!: boolean;

  tickets: LiveAgent.Ticket[] = [];
  showAddTicket: boolean = false;
  dirtyFetchedDepartments: LiveAgent.Department[] = [];
  environment!: string;

  async created() {
    this.refreshLiveAgentTickets();
    this.refreshDepartments();
  }
  get liveAgentTickets() {
    if (!this.tickets || this.tickets.length < 1) return [];
    return this.tickets;
  }
  async refreshDepartments() {
    axios.get(`/data-api/ticket/live-agent/departments`).then((response) => {
      this.dirtyFetchedDepartments = response?.data;
    });
  }
  async delayedRefreshLiveAgentTickets() {
    setTimeout(this.refreshLiveAgentTickets, 5000);
  }
  async refreshLiveAgentTickets() {
    const response = await FetchLiveAgentTickets({
      job: { job: { name: this.jobName } },
    });
    this.tickets = response;
  }
  onModalClose() {
    this.showAddTicket = false;
  }
  @Watch('refreshTickets')
  onRefreshTickets(v) {
    if (v) this.delayedRefreshLiveAgentTickets();
  }
  @Emit('message-created')
  message_created() {
    return true;
  }
}
