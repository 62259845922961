
import { Vue, Component, Watch } from 'vue-property-decorator';
import axios from 'axios';
import { Contact } from '@gid/models';
import { RequestQueryBuilder, CondOperator } from '@dataui/crud-request';
import PartnerListItem from './PartnerListItem.vue';

@Component({
  components: {
    PartnerListItem,
  },
})
export default class PartnerList extends Vue {
  jobName: string | undefined = '';
  partners: object[] = [];
  partnersPotential: object[] = [];
  partnerContacts: Contact[] = [];
  jobApiId: string = '';
  jobSfid: string = '';
  limit: number = 10;
  $refs!: {
    partnersModal: HTMLFormElement;
  };
  rejectionReasons: string[] = [];
  async created() {
    this.rejectionReasons = await this.fetchRejectionReasons();
  }
  get limitOptions() {
    return [5, 10, 25, 50, 100];
  }
  @Watch('limit', {
    deep: true,
  })
  searchChange() {
    this.refreshPartnerList();
  }
  async viewPartners(item) {
    this.jobName = item.job.name;
    this.jobApiId = item.job.api_id;
    this.jobSfid = item.job.sfid;
    this.$refs.partnersModal.show();
    this.partners = [];
    this.partnersPotential = [];
    this.refreshPartnerList();
  }
  async refreshPartnerList() {
    const qualifiedForJobUrl = `/api/admin/partners/qualified-for-job/${this.jobSfid}`;
    const [partners, partnersPotential] = await Promise.all([
      axios
        .get(qualifiedForJobUrl, {
          params: {
            in_radius: true,
            limit: this.limit,
          },
        })
        .then((response) => {
          return response.data;
        }),

      axios
        .get(qualifiedForJobUrl, {
          params: {
            only_potential: true,
            in_radius: true,
            limit: this.limit,
          },
        })
        .then((response) => {
          return response.data;
        }),
    ]);
    const accountIds = [...partners, ...partnersPotential]
      .map((p) => {
        return p.partner_id;
      })
      .filter((el) => el);
    const partnerContacts = await this.fetchContacts(accountIds);

    const matchPartnerWithContacts = (partners, contacts) => {
      return partners.map((p) => {
        const thisPartnerContacts = contacts.filter(
          (pc) =>
            pc.accountId === p.partner_id &&
            (pc.mobilePhone || pc.phone) &&
            pc.email,
        );
        let pickedPartnerContacts: Contact[] | object = [];
        if (thisPartnerContacts.length) {
          pickedPartnerContacts = thisPartnerContacts;
        }
        return {
          ...p,
          ...{
            contacts: pickedPartnerContacts,
          },
        };
      });
    };
    this.partners = matchPartnerWithContacts(partners, partnerContacts);
    this.partnersPotential = matchPartnerWithContacts(
      partnersPotential,
      partnerContacts,
    );
  }
  async fetchRejectionReasons() {
    const rejectJobReasons = 'Reject_Job_Reasons';
    return axios
      .get('/data-api/sf-picklist', {
        params: {
          api_names: rejectJobReasons,
          values_only: true,
        },
      })
      .then((r) =>
        r.data?.[rejectJobReasons]?.Metadata?.customValue.map(
          (v) => v.valueName,
        ),
      )
      .catch(() => {
        return [];
      });
  }
  async fetchContacts(accountIds) {
    const queryString = RequestQueryBuilder.create({
      filter: {
        field: 'account.id',
        operator: CondOperator.IN,
        value: accountIds,
      },
      sort: [{ field: 'createdAt', order: 'ASC' }],
    }).query();
    return axios
      .get(`/data-api/contact?${queryString}`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  }
}
