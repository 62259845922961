
/* eslint-disable no-unused-vars */

import axios from 'axios';
import { BIconFileEarmarkRuled, BIconCardChecklist } from 'bootstrap-vue';
import { Vue, Component, Watch } from 'vue-property-decorator';
import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import CrudListPagination from '@gid/vue-common/components/crud-list/CrudListPagination.vue';
import CrudListTable from '@gid/vue-common/components/crud-list/CrudListTable.vue';
import CrudListErrorMessage from '@gid/vue-common/components/crud-list/CrudListErrorMessage.vue';
import JobSelector from './JobSelector.vue';
import OpportunitySelector from './OpportunitySelector.vue';
import AccountSelector from './AccountSelector.vue';
import WarningBadges from '@/components/accounting/WarningBadges.vue';
import {
  JobTypeEnum,
  AccountingDocument,
  AccountingDocumentLineItem,
  Account,
  JobStatusEnum,
  AccountingDocumentTargetEnum,
  AccountingDocumentStatusEnum,
  AccountingDocumentTypeEnum,
  UserRoleEnum,
} from '@gid/models';
import {
  AccountingDraftsModule,
  AccountingDraftsActionsEnum,
  DraftFiltersAccountOption,
  DraftFiltersJobOption,
} from '../../store/accounting-drafts.module';
import {
  CreateQueryParams,
  RequestQueryBuilder,
  SFields,
  SConditionAND,
} from '@dataui/crud-request';
import AdvancedSearch from '@gid/vue-common/components/filters/AdvancedSearch.vue';
import moment from 'moment';

import { getModule } from 'vuex-module-decorators';
import Api from '@gid/vue-common/api';

interface AccountingDocumentLineItemWithHash
  extends AccountingDocumentLineItem {
  hash: string;
}

@Component({
  components: {
    DatePicker,
    CrudListPagination,
    CrudListTable,
    CrudListErrorMessage,
    BIconCardChecklist,
    BIconFileEarmarkRuled,
    JobSelector,
    AccountSelector,
    OpportunitySelector,
    WarningBadges,
    AdvancedSearch,
  },
})
export default class DraftInvoices extends Vue {
  brandLoading: boolean = false;
  partnerLoading: boolean = false;
  customerLoading: boolean = false;
  jobLoading: boolean = false;
  brands: DraftFiltersAccountOption[] = [];
  partners: DraftFiltersAccountOption[] = [];
  customers: DraftFiltersAccountOption[] = [];
  jobs: DraftFiltersJobOption[] = [];
  jobTypes: JobTypeEnum[] = [];

  inProgress: boolean = false;
  loadingDrafts: boolean = false;
  tooManyJobs: boolean = true;
  jobCountPreview: number = -1;
  date_options = {
    format: 'L',
    locale: moment.locale(),
    minDate: false,
  };
  createAction: any = { name: 'create' };
  uploadDocumentFile: any = null;
  uploadDocumentNumber: string = '';
  uploadDocumentIssueDate: Date | '' = '';
  partial: AccountingDocument | null = null;
  partialFields = [
    { key: 'position', label: 'Pos.', class: 'text-right text-nowrap' },
    { key: 'jobNumber', label: 'int.Auftragsnr.', class: 'text-nowrap' },
    { key: 'externalOrderId', label: 'ext.Auftragsnr.' },
    { key: 'deliveryDate', label: 'Lieferdatum' },
    { key: 'description', label: 'Beschreibung' },
    { key: 'quantity', label: 'Anzahl', class: 'text-right' },
    { key: 'unitPrice', label: 'Einzelpreis', class: 'text-right' },
    { key: 'totalPrice', label: 'Total', class: 'text-right' },
  ];
  partialSelected: AccountingDocumentLineItem[] = [];
  advancedFilterOptions = [
    {
      label: this.$i18n.t('filters.completed-date'),
      type: 'completed_date',
      component: 'from-to-filter',
    },
  ];
  filterAdvancedSearch: { [key: string]: any } = {};
  $refs!: {
    fileInput: HTMLFormElement;
  };
  preInvoiceStatuses = [
    JobStatusEnum.WAITING_PREPAYMENT,
    JobStatusEnum.OPEN,
    JobStatusEnum.ACCEPTED,
    JobStatusEnum.APPOINTMENT_CONFIRMED,
    JobStatusEnum.ON_HOLD,
    JobStatusEnum.STARTED,
    JobStatusEnum.APPROVAL_PENDING,
    JobStatusEnum.FINISHED,
    JobStatusEnum.COMPLETED,
  ];

  created() {
    this.countJobs();
  }

  // Computed
  get store(): AccountingDraftsModule {
    return getModule(AccountingDraftsModule, this.$store);
  }
  get columns() {
    return [
      {
        key: 'number',
        sortable: true,
        label: this.$t('accounting.common.document-number'),
      },
      {
        key: 'targetName',
        sortable: true,
        label: this.$t('accounting.common.target-name'),
      },
      {
        key: 'totalAmount',
        sortable: true,
        label: this.$t('accounting.common.total-amount'),
      },
      {
        key: 'type',
        sortable: true,
        label: this.$t('accounting.common.document-type'),
      },
      { key: 'status', label: this.$t('job.table.heading.actions') },
    ];
  }

  get jobTypeOptions() {
    return [
      { value: undefined, text: 'None' },
      ...Object.keys(JobTypeEnum).map((key) => {
        return { value: JobTypeEnum[key], text: JobTypeEnum[key] };
      }),
    ];
  }

  get uploadDisabled() {
    return this.store.selectedCount !== 1;
  }

  validateFileType(event) {
    const file = event.target.files[0];

    const allowedFileType = ['text/xml', 'application/pdf'];
    if (file && !allowedFileType.includes(file.type)) {
      this.uploadDocumentFile = null;
      this.$refs['fileInput'].reset();
      this.$bvToast.toast(
        'The file you have selected is not supported. Please only use PDF or XML file.',
        {
          title: 'Error',
          variant: 'danger',
        },
      );
    }
  }

  get cnDelayDaysAfterInvoice() {
    return AccountingDocument.CREDIT_NOTE_DELAY_DAYS_AFTER_INVOICE;
  }

  get cnDelayDaysAfterJobCompleted() {
    return AccountingDocument.CREDIT_NOTE_DELAY_DAYS_AFTER_COMPLETED;
  }

  get isDocumentCreationDelayed() {
    return (item) => {
      return (
        item.target.accountingPerJob &&
        item.target.role === UserRoleEnum.PARTNER &&
        item.type == AccountingDocumentTypeEnum.CREDIT_NOTE &&
        (typeof item.daysSinceInvoicesSent === 'number'
          ? item.daysSinceInvoicesSent <
            AccountingDocument.CREDIT_NOTE_DELAY_DAYS_AFTER_INVOICE
          : typeof item.daysSinceJobsCompleted === 'number'
          ? item.daysSinceJobsCompleted <
            AccountingDocument.CREDIT_NOTE_DELAY_DAYS_AFTER_COMPLETED
          : false)
      );
    };
  }

  get submitDisabled() {
    switch (this.createAction?.name) {
      case 'create':
        return !(this.store.selectedCount >= 1);
      case 'upload':
        return (
          this.uploadDisabled ||
          !this.uploadDocumentFile ||
          !this.uploadDocumentNumber?.length ||
          !this.uploadDocumentIssueDate
        );
      default:
        return true;
    }
  }

  get createOptions() {
    return [
      { name: 'create' },
      { name: 'upload', $isDisabled: this.uploadDisabled },
    ];
  }

  @Watch('uploadDisabled')
  createOptionsChanged(value) {
    if (this.uploadDisabled && this.createAction?.name === 'upload') {
      this.createAction = { name: 'create' };
    }
  }

  @Watch('store.filters', {
    deep: true,
  })
  watchSearchChange(value) {
    this.searchChange(value);
  }

  @Watch('filterAdvancedSearch')
  watchFilterAdvancedSearch(value) {
    this.searchChange(value);
  }

  searchChange(value) {
    this.tooManyJobs = true;
    this.jobCountPreview = -1;
    this.countJobs();
  }

  async countJobs() {
    const endpoint = `${this.store.crudEndpoint}/count`;
    const query = this.buildJobQuery();

    const noPagingQuery = {
      ...query,
      page: undefined,
      limit: undefined,
    };
    const noPagingQb = RequestQueryBuilder.create(noPagingQuery);
    const queryString = noPagingQb.query();
    return Api.get(`${endpoint}?${queryString}`, {
      autoCancelScope: endpoint,
    }).then((response) => {
      this.jobCountPreview = Number(response.data);
      this.tooManyJobs = this.jobCountPreview < 0 || this.jobCountPreview > 500;
    });
  }

  isDocumentDelayed(doc: AccountingDocument) {}

  buildJobQuery() {
    const search: Array<SFields | SConditionAND> = [
      {
        'JobView.status': {
          $in: this.store.filters.preInvoicing
            ? this.preInvoiceStatuses
            : [JobStatusEnum.COMPLETED],
        },
      },
    ];
    const searchBrandOr: Array<SFields | SConditionAND> = [];

    if (this.store.filters.filterType === 'dateRange') {
      if (this.store.filters.dateFrom) {
        search.push({
          'JobView.deliveryDate': {
            $gte: this.store.filters.dateFrom,
          },
        });
      }
      if (this.store.filters.dateTo) {
        search.push({
          'JobView.deliveryDate': {
            $lte: this.store.filters.dateTo,
          },
        });
      }
    }

    if (this.store.filters.filterType === 'jobList') {
      if (this.store.filters.selectedJobs?.length) {
        search.push({
          'JobView.id': {
            $in: this.store.filters.selectedJobs.map((j) => j.id),
          },
        });
      }
    }

    const documentTarget: AccountingDocumentTargetEnum[] = [];
    const brandVariant = this.store.filters.targetBrandVariant;
    const customerVariant = this.store.filters.targetCustomerVariant;
    if (this.store.filters.targetBrand) {
      if (brandVariant === 'any' || brandVariant === 'brand') {
        documentTarget.push(AccountingDocumentTargetEnum.BRAND);
      }
      if (brandVariant === 'any' || brandVariant === 'brand_1') {
        documentTarget.push(AccountingDocumentTargetEnum.BRAND_1);
      }
      if (brandVariant === 'any' || brandVariant === 'brand_2') {
        documentTarget.push(AccountingDocumentTargetEnum.BRAND_2);
      }
    }
    if (this.store.filters.targetPartner) {
      documentTarget.push(AccountingDocumentTargetEnum.PARTNER);
    }
    if (this.store.filters.targetCustomer) {
      if (customerVariant === 'any' || customerVariant === 'customer') {
        documentTarget.push(AccountingDocumentTargetEnum.CUSTOMER);
      }
      if (customerVariant === 'any' || customerVariant === 'customer_second') {
        documentTarget.push(AccountingDocumentTargetEnum.CUSTOMER_2);
      }
    }
    if (this.store.filters.targetSupplier) {
      documentTarget.push(AccountingDocumentTargetEnum.SUPPLIER);
    }
    if (this.store.filters.targetAffiliate) {
      documentTarget.push(AccountingDocumentTargetEnum.AFFILIATE);
    }
    if (documentTarget.length) {
      search.push({
        documentTarget: {
          $in: documentTarget,
        },
      });
    }

    if (this.filterAdvancedSearch?.and?.length) {
      const completedDates = this.filterAdvancedSearch.and.find(
        (filter) => filter.field === 'completed_date',
      );
      search.push({
        completedDates,
      });
    }

    if (
      this.store.filters.targetBrand &&
      this.store.filters.selectedBrands.length
    ) {
      const brandIds = this.store.filters.selectedBrands.map(
        (brand) => brand.id,
      );
      if (brandVariant === 'any') {
        searchBrandOr.push(
          ...[
            {
              'brand.id': {
                $in: brandIds,
              },
            },
            {
              'brand_1.id': {
                $in: brandIds,
              },
            },
            {
              'brand_2.id': {
                $in: brandIds,
              },
            },
          ],
        );
      } else {
        searchBrandOr.push({
          [`${brandVariant}.id`]: {
            $in: brandIds,
          },
        });
      }
    }
    if (
      this.store.filters.targetPartner &&
      this.store.filters.selectedPartners.length
    ) {
      search.push({
        'partner.id': {
          $in: this.store.filters.selectedPartners.map((partner) => partner.id),
        },
      });
    }
    if (
      this.store.filters.targetCustomer &&
      this.store.filters.selectedCustomers.length
    ) {
      const customerIds = this.store.filters.selectedCustomers.map(
        (customer) => customer.id,
      );
      if (customerVariant === 'any') {
        search.push({
          $or: [
            {
              'customer.id': {
                $in: customerIds,
              },
            },
            {
              'customer_2.id': {
                $in: customerIds,
              },
            },
          ],
        });
      } else {
        search.push({
          [`${customerVariant}.id`]: {
            $in: this.store.filters.selectedCustomers.map(
              (customer) => customer.id,
            ),
          },
        });
      }
    }
    if (
      this.store.filters.targetAffiliate &&
      this.store.filters.selectedAffiliates.length
    ) {
      searchBrandOr.push({
        'affiliate.id': {
          $in: this.store.filters.selectedAffiliates.map(
            (affiliate) => affiliate.id,
          ),
        },
      });
    }
    if (
      this.store.filters.targetSupplier &&
      this.store.filters.selectedSuppliers.length
    ) {
      searchBrandOr.push({
        'products.supplierSfid': {
          $in: this.store.filters.selectedSuppliers.map(
            (supplier) => supplier.id,
          ),
        },
      });
    }
    if (
      this.store.filters.selectedJobTypes &&
      this.store.filters.selectedJobTypes.length
    ) {
      this.store.filters.selectedJobTypes.map((jobType) => {
        if (
          Object.keys(JobTypeEnum)
            .map((el) => JobTypeEnum[el])
            .includes(jobType)
        ) {
          search.push({
            [`jobTypes.${jobType}`]: {
              $eq: true,
            },
          });
        }
      });
    }
    if (
      this.store.filters.selectedOpportunities &&
      this.store.filters.selectedOpportunities.length
    ) {
      search.push({
        'opportunity.id': {
          $in: this.store.filters.selectedOpportunities.map((opp) => opp.id),
        },
      });
    }
    if (searchBrandOr.length > -1) {
      search.push({
        $or: searchBrandOr,
      });
    }
    const query: CreateQueryParams = {
      page: this.store.pagination.currentPage,
      limit: this.store.pagination.perPage,
      search: {
        $and: search,
      },
    };
    return query;
  }

  refreshJobList() {
    this.loadingDrafts = true;
    this.store[AccountingDraftsActionsEnum.ITEMS_FETCH]({
      query: this.buildJobQuery(),
      noPagination: true,
    }).finally(() => {
      this.loadingDrafts = false;
    });
    this.store.selectionSetAll(false);
  }

  submit() {
    this.store.clearLastError();
    let p;
    switch (this.createAction?.name) {
      case 'create':
        p = this.createDocuments();
        break;
      case 'upload':
        p = this.uploadDocument();
        break;
      default:
        return;
    }
    return p.then(() => {
      if (!this.store.error) {
        this.refreshJobList();
      }
    });
  }

  createDocuments() {
    const bulk = this.store.selectedItems.map((fullDoc) => {
      const doc = new AccountingDocument();
      doc.target = new Account();
      doc.target.id = fullDoc.target.id;
      doc.jobs = fullDoc.jobs;
      return doc;
    });

    this.inProgress = true;
    return axios
      .post(`${this.store.crudEndpoint}/bulk?${this.store.queryString}`, {
        bulk,
      })
      .then(() => {
        this.store.selectionSetAll(false);
      })
      .catch((error) => {
        this.store.setLastError({
          error,
        });
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  async uploadDocument() {
    const draft: AccountingDocument = new AccountingDocument();
    draft.target = new Account();
    draft.target.id = this.store.selectedItems[0].target.id;
    draft.inverse = true;
    draft.number = this.uploadDocumentNumber;
    draft.status = AccountingDocumentStatusEnum.RECEIVED;
    draft.jobs = this.store.selectedItems[0].jobs;

    this.inProgress = true;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        const firstComma = result.indexOf(',');
        resolve(result.slice(firstComma + 1));
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(this.uploadDocumentFile);
    })
      .then((documentContentBase64) => {
        return axios
          .post(`${this.store.crudEndpoint}?${this.store.queryString}`, {
            ...draft,
            issueDate: new Date(this.uploadDocumentIssueDate).toISOString(),
            documentContentBase64,
            mimeType: this.uploadDocumentFile.type,
          })
          .then(() => {
            this.store.selectionSetAll(false);
            this.uploadDocumentIssueDate = '';
            this.uploadDocumentNumber = '';
          })
          .catch((error) => {
            this.store.setLastError({
              error,
            });
          });
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  get partialTitle() {
    if (!this.partial) return '';
    let docTypeName = '';
    if (this.partial.type == AccountingDocumentTypeEnum.INVOICE) {
      docTypeName = 'Rechnung';
    } else if (this.partial.type == AccountingDocumentTypeEnum.CREDIT_NOTE) {
      docTypeName = 'Gutschrift';
    }
    return `Generate partial ${docTypeName} ${this.partial.number} for ${this.partial.target.name}`;
  }

  get partialTotal() {
    return this.partialSelected.reduce(
      (acc, item) => acc + Number(item.totalPrice),
      0,
    );
  }

  showPartial(document: AccountingDocument) {
    this.partial = document;
    this.partialSelected = [];
    this.$bvModal.show('partial-modal');
  }

  onPartialSelected(items: AccountingDocumentLineItem[]) {
    this.partialSelected = items;
  }

  async createPartial(document: AccountingDocument) {
    const doc = new AccountingDocument();
    doc.target = new Account();
    doc.target.id = document.target.id;
    doc.jobs = document.jobs;
    this.inProgress = true;
    try {
      await axios.post(
        `${this.store.crudEndpoint}/bulk?${this.store.queryString}`,
        {
          bulk: [doc],
          onlyLineItems: this.partialSelected.map(
            (item) => (item as AccountingDocumentLineItemWithHash).hash,
          ),
        },
      );
    } catch (error) {
      this.store.setLastError({
        error,
      });
    }
    this.partialSelected = [];
    this.inProgress = false;
    this.refreshJobList();
  }

  addMeasure(value, measure) {
    if (!measure || measure === 'Count' || measure === 'Stück') {
      return value;
    }
    if (measure === 'Euro') {
      return `${value} €`;
    }
    return `${value} ${measure}`;
  }
}
