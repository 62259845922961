
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import RejectJob from './RejectJob.vue';
import CallContacts from '../../../components/ticketing/CallContacts.vue';
import { GeneralInvoicingTargetEnum } from '@gid/models';
import { JobContact } from '@gid/vue-common/components/ticketing/interface/ticketing';

@Component({
  components: {
    CallContacts,
    RejectJob,
  },
})
export default class PartnerListItem extends Vue {
  @Prop({ default: null }) partner!: any;
  @Prop({ default: null }) jobName!: string;
  @Prop({ default: null }) jobApiId!: string;
  @Prop({ default: null }) ticketCreation!: boolean;
  @Prop({ default: null }) rejectionReasons!: string[];

  showRejectJob: boolean = false;

  get partnerRole(): GeneralInvoicingTargetEnum[] {
    return [GeneralInvoicingTargetEnum.PARTNER];
  }
  get partnerJobContacts(): JobContact[] | object {
    if (this.partner.contacts && this.partner.contacts.length) {
      return this.partner.contacts.map((c) => {
        return {
          ...c,
          ...{ jobRole: GeneralInvoicingTargetEnum.PARTNER },
        };
      });
    }
    return [];
  }
  @Emit('job-rejected')
  triggerRefreshPartners() {
    this.showRejectJob = false;
    return true;
  }
}
